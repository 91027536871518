import {
  ACQUISITION_CHANNEL,
  ACTION,
  BOTS,
  CONSENT_SCENARIOS,
  GOOGLE_CONSENT_DEFAULT,
  GOOGLE_CONSENT_UPDATED,
  LABEL,
  MARKETING_COOKIE,
  SEARCH_ENGINE,
  TRACKING_ID,
  UTM,
  VIMEO_EVENTS,
  YOUTUBE_EVENTS,
  platformID,
} from '../analytics/constants';

declare global {
  interface Window {
    OnetrustActiveGroups: string;
    
    gaGlobal: Record<string, unknown>; // {} object

    dataLayer: unknown[];  // [] object
    _hsq: unknown[];

    fbq: (...args: unknown[]) => void; // functions w. spread syntax because args may vary
    lintrk: (...args: unknown[]) => void;
    gtag: (...args: unknown[]) => void;
    Intercom: (...args: unknown[]) => void;

    analytics: {
      on: (...args: unknown[]) => void;
      load: (...args: unknown[]) => void;
      ready: (...args: unknown[]) => void;
      page: (...args: unknown[]) => void;
      track: (...args: unknown[]) => void;
      identify: (...args: unknown[]) => void;
    }; // {} object w. several .methods() (= functions)

    cookieStore: Record<string, Record<string, unknown>> & {
      getAll: (...args: unknown[]) => void;
      delete: (...args: unknown[]) => void;
    }; // {} object w. nested {} objects or .createSignup() method (= function)

    Optanon: {
      ToggleInfoDisplay: (...args: unknown[]) => void;
      GetDomainData: () => Record<string, unknown> & {
        Groups: {
          OptanonGroupId: string,
          FirstPartyCookies: unknown[]
        }[];
      }
    };
  }
}

/* GENERAL LOGIC */

export const isBot = () => BOTS.some(i => window.navigator.userAgent?.toLowerCase()?.includes(i));

export const appendScript = (
  src: string, 
  props: Record<string, unknown> = {}, // optional bc {} fallback value provided
  callback?: (...args: unknown[]) => void // ?: = optional
) => {
  const script = document.createElement('script');
  /^(http|\/\/)/.test(src) ? script.src = src : script.innerHTML = src;
  Object.keys(props).map(k => script.setAttribute(k, props[k] as string));
  document.head.appendChild(script);
  if (callback) script.onload = callback;
};

export const obscureString = (str: string) => {
  if (!str) return undefined;
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const getRandomChar = () => chars[Math.floor(Math.random() * chars.length)];
  const swapChar = (str: string) => (/^[^\w]+$/i.test(str) ? str : getRandomChar());
  const applySwap = (str: string) => str.split('').map(i => (i = swapChar(i))).join('');

  const isEmail = /^[^@]*@[^.@]*\.[A-Za-z0-9.]+$/i.test(str);
  if (isEmail) {
    const parts = str.split('@');
    const obscuredUsername = applySwap(parts[0]);
    const obscuredDomain = parts[1].split('.').map(i => applySwap(i)).join('.');
    return `${obscuredUsername}@${obscuredDomain}`;
  } else {
    return applySwap(str);
  }
};

export const applyFuncToObjectValues = (
  obj: Record<string, unknown>,
  func: (val: string) => void
) => {
  const entries = Object.entries(obj);
  const process = entries.map(([k, v]) => [k, func(v as string)]);
  return Object.fromEntries(process);
};


/* DOM RENDER

window['OneTrust']?.ToggleInfoDisplay();
load libs, force-load '_ga' cookie, send pageviews to HS, FB, LI + create cookie
funcs inside ready() proimise are callbacks on successful Segment destinations load
*/
export const eventsOnBrowserLoad = (activeGroups: unknown[]) => {

  // Load scripts for each active cookie group
  activeGroups?.forEach(i => CONSENT_SCENARIOS[i as string]?.forEach(j => appendScript(j as string)));

  // Return if 'C0002', that loads Segment and all its destinations, is disabled
  if (!activeGroups?.includes('C0002')) return;

  gtagCall('consent', 'update',  GOOGLE_CONSENT_UPDATED);
  gtagCall('event', 'initialize');

  window.analytics?.ready(() => {
    segmentPage(ACTION.PageViewed, {
      url: window?.location.href || '',
      referrer: 'consent granted/browser (re)load'
    });

    setMarketingCookie();
    console.log(history.length - 1, clientId());
  });
};


/* CONSENT UPDATE

1st-party cookies (our domains) are removed here: not for HttpOnly, last step is browser reload
3rd-party cookies (other domains) are removed by blocking relevant libraries in eventsOnBrowserLoad();
*/
export const eventsOnConsentUpdate = (activeGroups: unknown[]) => {

  // 1. helpers
  const getDisallowedCookies = (activeGroups: unknown[]) => {
    if (!window.Optanon) return;
    let cookiesToDelete: unknown[] = []
    for (const i of window.Optanon.GetDomainData()?.Groups) {
      if (!activeGroups.includes(i.OptanonGroupId))
        i.FirstPartyCookies.map(j => cookiesToDelete.push((j as Record<string, unknown>).Name));
    };
    return cookiesToDelete;
  };

  const makeCookieOutdated = (cname: unknown, cpath: unknown, domain: unknown) => {
    const t = 'Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = `${cname}=; Max-Age=0; expires=${t}; path=${cpath}; domain=.${domain}`;
    document.cookie = `${cname}=; Max-Age=0; expires=${t}; path=${cpath}; domain=${domain}`;
    document.cookie = `${cname}=; Max-Age=0; expires=${t}; path=${cpath}`;
  };
  
  const iterateOverCookies = (cookies: unknown[], paths: unknown[], domain: unknown) => {
    cookies.forEach(cname => setTimeout(() => {
      paths.forEach(cpath => makeCookieOutdated(cname, cpath, domain))
    }, 30));
  };

  const iterateOverDomains = (cookies: unknown[], paths: unknown[]) => {
    const subdomain = location.hostname.match(/\.(.*$)/)?.[1];
    const preview = subdomain && ['perpetua-home.pages.dev', 'gatsbyjs.io'].includes(subdomain);
    iterateOverCookies(cookies, paths, location.hostname);
    if (preview) iterateOverCookies(cookies, paths, subdomain);
  };

  const removeFirstPartyCookiesPromise = (cookies: unknown[], paths: unknown[]) => {
    return new Promise((resolve) => {
      iterateOverDomains(cookies, paths);
      setTimeout(() => resolve(document.cookie), 1000);
    });
  };

  const removeFirstPartyCookiesCallback = async (cookies: unknown[], paths: unknown[]): Promise<any> => {
    await removeFirstPartyCookiesPromise(cookies, paths);
    location.reload();
  };

  const sendDefaultConsentOnCookieRemoval = () => {
    if (!activeGroups?.includes('C0002')) { gtagCall('consent', 'update', GOOGLE_CONSENT_DEFAULT); }
  };

  // 2. logic
  // update cookie paths history
  const onClientEntryPathname = window.location.pathname?.replace(/\/$/, '') || '/';
  const currentJourney = localStorage.getItem('journey')?.split(';') || [];
  const updatedJourney = [...new Set([ ...currentJourney, onClientEntryPathname])]
  localStorage.setItem('journey', updatedJourney.join(';'));

  // block on first client entry
  if (currentJourney.length === 0) return;

  // not necessarily (in)active, just disalowed
  const disallowed = getDisallowedCookies(activeGroups) || [];
  if (disallowed.length === 0) return;

  const currentFirstPartyCookies = Object.keys(Object.fromEntries(document.cookie.split('; ').map(c => c.split('='))));
  const cookiesToDelete = currentFirstPartyCookies.filter(i => disallowed.includes(i));
  if (cookiesToDelete.length === 0) return;

  // alert(`TO DISABLE: ${cookiesToDelete}`);
  const cookiePathsToDelete = [...new Set([ ...updatedJourney, '/'])];
  removeFirstPartyCookiesCallback(cookiesToDelete, cookiePathsToDelete);
  sendDefaultConsentOnCookieRemoval();
};


export const loadLogic = () => {
  eventsOnBrowserLoad(window.OnetrustActiveGroups?.split(',').filter(Boolean)); // browser (re)load

  window.addEventListener('OneTrustGroupsUpdated', ((ev: CustomEvent) => {
    eventsOnBrowserLoad(ev.detail);
    eventsOnConsentUpdate(ev.detail);
  }) as EventListener); // consent update
};

export const setMarketingCookie = () => {
  if (document.cookie.indexOf(MARKETING_COOKIE) !== -1) return;
  
  let props: Record<string, unknown> = {};
  const getProps = new URL(location.href).searchParams;
  Object.values({
    ...UTM,
    ...platformID
  }).filter(i => getProps.has(i)).map(j => props[j] = getProps.get(j));

  const paid = ACQUISITION_CHANNEL.find(i => i.key.test(window.location.search))?.value;
  const free = SEARCH_ENGINE.some(i => document.referrer?.includes(i)) ? 'Organic' : 'Direct';
  
  props = {
    acquisition_channel_source: paid || free,
    google_analytics_client_id: clientId(),
    ...props
  };

  Object.keys(props).map(k => localStorage?.setItem(k, props[k] as string));
  const domain = !window.location.port 
    ? window.location.hostname.split('.').slice(-3,).join('.') 
    : window.location.hostname
  document.cookie = `${MARKETING_COOKIE}=${JSON.stringify(props)};domain=.${domain};path=/`;
};


/* TRACKING CALLS */

// 'event' can be object e.g. in gtag('js', new Date())
// gtag() doesn't accept props as empty {} => need a condition
export const gtagCall = (
  command: string,
  event: string | Record<string, unknown>,
  props?: Record<string, unknown>
) => {
  window.gtag && (
    props 
      ? window.gtag(command, event, props) 
      : window.gtag(command, event)
  )
};

export const clientId = () => window.gaGlobal?.vid || '';

export const segmentIdentify = (id: string, props: Record<string, unknown>) => {
  window.analytics?.ready(() => window.analytics.identify(id, props));
};

export const segmentPage = (event: string, props: Record<string, unknown>) => {
  // page_view event initiates clientId creation so should come first
  gtagCall('event', 'page_view');

  window.analytics?.ready(() => window.analytics.page(event, { ...props, clientId: clientId() }));
};

export const segmentTrack = (event: string, props: Record<string, unknown>) => {
  gtagCall('event', event.replaceAll(' ', '_'), props);

  window.analytics?.ready(() => {
    window.analytics.track(event, {
      url: window?.location.href || '', // for debug, GA4 auto captures 'page_location'
      clientId: clientId(),
      ...props
    });
  });
};


/* HUBSPOT */

// sets 'Original Source' in contact props
export const hubspotTrack = (email: string, event: string) => {
  window._hsq && window._hsq.push(['identify', { email: email }]);
  window._hsq && window._hsq.push(['trackEvent', { id: event }]);
};

/*
input data + 'PerpetuaSessionData' cookie used; if no cookie - send current clientId only
if session starts on site - clientId fetched from cookie, if on app. - from gaGlobal.vid
if events are no longer sent to HS, check field names in form settings on HS side
if a field is not on HS:
  {name: 'newField', value: 'newValue'} - HS API will reject this
  {name: 'newField', value: ''} - this will pass
*/
export const hubspotSignup = (
  portalId: string,
  formId: string,
  data: Record<string, unknown>
) => {
  const marketing = JSON?.parse(
    document.cookie.match(`(^|;)\\s*${MARKETING_COOKIE}\\s*=\\s*([^;]+)`)?.pop() ||
      `{ "google_analytics_client_id": "${clientId()}" }`
  );

  const entries = Object.entries({ ...data, ...marketing });
  const merged = entries.map(([name, value]) => ({ name, value }));
  const request = { fields: merged, submittedAt: +new Date() };

  const xhr = new XMLHttpRequest();
  const apiUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  xhr.open("POST", apiUrl);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify(request));
};


/* PAID SOCIAL */

// for advanced matching on form submit (multiple inits per page allowed)
export const facebookInitialize = (payload: Record<string, unknown>) => {
  window.fbq && window.fbq?.('init', TRACKING_ID.Facebook, payload);
};

export const facebookTrack = (
  event: string,
  payload: Record<string, unknown>
) => window.fbq && window.fbq('trackCustom', event, payload);

export const linkedinTrack = (id: string) => window.lintrk && window.lintrk('track', { conversion_id: id });

/*
Facebook events are server-side so pixel helper extension won't work
Segment Conversion API (actions) destination hashes PII
PII prop names have Meta / Facebook format

formSubmitTrack({
  label: LABEL.Demo,
  adSpend: adSpend?.hsID,
  paidSocialEvents: PAID_SOCIAL_NEW.lead,
  facebookPayload: {
    em: email,
    fn: firstName,
    ln: lastName,
    country: location?.name,
    ph: phoneNumber
  }
});
*/
export const formSubmitTrack = (props: Record<string, unknown> & {
  paidSocialEvents: Record<string, Record<string, unknown>>;
  facebookPayload: Record<string, unknown>;
}) => {

  const label = props.label as string;
  const adSpend = props.adSpend as string;
  const facebookPayload = props.facebookPayload;
  const {Facebook, Linkedin, Bing} = props.paidSocialEvents;
  
  const [facebookEvent, linkedinEvent, bingEvent] = /^P[2-8]$/.test(adSpend)
    ? [Facebook.MQL, Linkedin.MQL, Bing.MQL]
    : [Facebook.noMQL, Linkedin.noMQL, Bing.noMQL]


  const obscuredFacebookPayload = applyFuncToObjectValues(facebookPayload, obscureString);

  const leadEvents: string[] = [
    LABEL.Demo,
    LABEL.Signup,
    LABEL.Prism,
    LABEL.Startup,
    LABEL.AMC,
    LABEL.BI
  ];

  const defaults = {
    label: label,
    category: leadEvents.includes(label) ? `${label} ${bingEvent}` : bingEvent,
    facebookPayload: {
      eventName: facebookEvent, 
      ...obscuredFacebookPayload
    }
  };

  const payload = adSpend
    ? {...defaults, ...{adSpend: adSpend}}
    : defaults;

  facebookPayload.em && hubspotTrack(facebookPayload.em as string, ACTION.FormSubmitted);
  linkedinTrack(linkedinEvent as string);
  facebookInitialize(facebookPayload);
  segmentTrack(ACTION.FormSubmitted, payload);
};


/* VIDEO TRACKING */

interface YoutubeResponse {
  target: Record<string, unknown> & {
    getPlayerState: () => number;
    getCurrentTime: () => number;
    getDuration: () => number;
  };
}

export const youtubeTrack = (id: string) => {

  function playerState(ev: YoutubeResponse) {
    const label = YOUTUBE_EVENTS[ ev.target.getPlayerState() ];
    segmentTrack(ACTION.VideoPlayback, {label: label, videoId: id});
  }

  function percentWatched(ev: YoutubeResponse) {
    const breakpoint = (perc: number) => {
      const track = () => {
        if ((ev.target.getCurrentTime() / ev.target.getDuration()) * 100 < perc) return;
        const label = `${perc === 99 ? 100 : perc}% watched`;
        segmentTrack(ACTION.VideoPlayback, {label: label, videoId: id});
        clearInterval(timer);
      }
      const timer = setInterval(track, 1000);
    };
    [25, 50, 75, 99].map(i => breakpoint(i));
  }

  const settings = { videoId: id, width: '100%', height: '100%'};
  const playerVars = {playsinline: 1, origin: window.location.host};
  const playerEvents = {onReady: percentWatched, onStateChange: playerState};

  const youtube = window['YT' as keyof typeof window];
  new youtube.Player('yt-player', {playerVars: playerVars, events: playerEvents, ...settings});
};

export const vimeoTrack = () => {
  const iframe = document.querySelector('iframe');
  const player = new window['Vimeo' as keyof typeof window].Player(iframe);
  const id = iframe?.src?.match(/\/video\/(\d+)/);

  Object.keys(VIMEO_EVENTS).map(ev => {
    player.on(ev, function () {

      segmentTrack(ACTION.VideoPlayback, {
        label: VIMEO_EVENTS[ev], 
        videoId: id && id[1]
      });

    });
  });
};


/* OTHER */

/*
pass URL params between domain and subdomain
JSON.parse(document.cookie.match(`(^|;)\\s*${MARKETING_COOKIE}\\s*=\\s*([^;]+)`)?.pop() || '{}');
*/
export const appendUrlParams = (url: string) => {
  const props = { ...window?.localStorage };
  const params = Object.keys(props).filter(i => /fbclid|li_fat_id|gclid|utm_/.test(i));
  const append = params.map((i: string) => `${i}=${props[i]}`).join('&');
  return append ? url + (url.includes('?') ? '&' : '?') + append : url;
};
