import React, { Dispatch, SetStateAction } from "react";

type SwitcherType = "dropdown" | "inline";

interface AppContextProps {
  switcherType: SwitcherType;
  setCurrentSwitcher: Dispatch<SetStateAction<SwitcherType>> | null;
}

interface AppProviderType {
  children: any;
}

export const AppContext = React.createContext<AppContextProps>({
  switcherType: "dropdown",
  setCurrentSwitcher: null
});

export const AppProvider: React.FC<AppProviderType> = ({ children }) => {
  const [currentSwitcher, setCurrentSwitcher] =
    React.useState<SwitcherType>("dropdown");

  return (
    <AppContext.Provider
      value={{
        switcherType: currentSwitcher,
        setCurrentSwitcher
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => React.useContext(AppContext);
