// import { StrictMode } from "react";
// gatsbyjs.com/docs/reference/built-in-components/gatsby-script/
import React from 'react';
import RootElement from './src/components/RootElement';
require('./src/styles/global.scss');

const {
  TRACKING_ID,
  TRACKING_LIBS,
  IS_CHINA_SITE,
  COOKIEPRO_SETTINGS,
  GOOGLE_CONSENT_DEFAULT,
  ACTION
} = require('./src/utils/analytics/constants');

const {
  isBot,
  gtagCall,
  clientId,
  appendScript,
  loadLogic,
  segmentPage
} = require('./src/utils/analytics/index');

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

export const onClientEntry = () => {
  if (isBot() || IS_CHINA_SITE) return;
  
  appendScript(TRACKING_LIBS.GtagLibrary, {async: true});
  appendScript(TRACKING_LIBS.GtagFunction);

  gtagCall('consent', 'default', GOOGLE_CONSENT_DEFAULT);
  gtagCall('js', new Date());
  gtagCall('config', TRACKING_ID.GoogleAnalytics, {send_page_view: false});
  gtagCall('config', TRACKING_ID.GoogleAds, {send_page_view: false});

  appendScript(TRACKING_LIBS.VWO);
  appendScript(TRACKING_LIBS.Youtube);
  // appendScript(TRACKING_LIBS.CookieProAutoblock);
  // appendScript(TRACKING_LIBS.CookieProTemplates, COOKIEPRO_SETTINGS, () => window.onload = loadLogic); // main
};

export const onInitialClientRender = () => {
  if (isBot() || IS_CHINA_SITE) return;

  appendScript(TRACKING_LIBS.CookieProAutoblock);
  appendScript(TRACKING_LIBS.CookieProTemplates, COOKIEPRO_SETTINGS, loadLogic);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (isBot() || IS_CHINA_SITE) return;
  
  if (!prevLocation) return;

  segmentPage(ACTION.PageViewed, {
    url: location?.pathname || window?.location.href,
    referrer: prevLocation?.pathname || '',
  });
  console.log(history.length - 1, clientId());
};
